// WARNING
// React navigation cant differentiate between /:foo and /foo (hard reload cases)
// We're using getStateFromPath to handle params on path

export const Routes = {
  screens: {
    Shop: {
      screens: {
        HomeScreen: '',
        ResetPasswordScreen: '/set-password',
        BrandDetailScreen: '/brand',
        ProductDetailScreen: '/product',
        ShoppingBagScreen: '/order',
        ShippingScreen: '/order/shipping',
        PaymentControl: '/order/payment',
        PaymentConfirmationScreen: '/order/confirmation',
        PaymentConfirmationSuccessScreen: '/order/success',
        WishlistScreen: '/wishlist',
        MyOrdersScreen: '/my-orders',
        OrderDetailScreen: '/my-orders/:idOrder',
        MyReviewScreen: '/my-review',
        CreateReviewScreenDesktop: '/create-review',
        EditReviewScreenDesktop: '/edit-review',
        ProfileScreen: '/my-account',
        MyAddressScreen: '/shipping-address',
        InterestScreen: '/my-interest',
        SignupScreen: '/sign-up',
        QuickRegisterSignUpScreen: '/quick-registration/sign-up',
        ForgotPasswordScreen: '/forgot-password',
        QuickRegistrationScreen: '/quick-registration',
        QuickRegistrationVerifyScreen: '/quick-registration/verify',
        EmailVerification: '/email-verification',
        PromotionScreen: '/promo',
        PromoCategoryDetailScreen: '/promo/:slug',
        OtpVerificationControlScreen: '/otp-verification',
        CampaignScreen: '/promotion/:slug',
        CampaignV2Screen: '/campaign/:slug',
        CampaignV2TypeGiftScreen: '/gift-promo/:slug',
        BestSellerScreen: '/best-seller',
        ContactUsInfoScreen: '/contact-us',
        TermsAndConditionScreen: '/termsandconditions',
        PrivacyPolicyScreen: '/privacypolicy',
        AboutUsScreen: '/about-us',
        AffiliateWithUsScreen: '/affiliate-with-us',
        HelpScreen: '/help-center',
        StoreScreen: '/our-store',
        CareersScreen: '/careers',
        NewArrivalScreen: '/just-arrived',
        EGiftCardListScreen: '/e-gift-card',
        CompleteProfileScreen: '/complete-profile',
        CategoryDetailScreen: '/category',
        FlashSaleScreen: '/flash-sale',
        ShopByCategoriesScreen: '/categories',
        ShopByBrandsScreen: '/brands',
        AllReviewProductDetailScreen: '/all-reviews',
        BabyRegistryScreen: '/baby-registry/:id?',
        FormAddressScreen: '/add-address',
        FormEditAddressScreen: '/edit-address/:id',
        PaymentFailed: '/payment-failed',
        PaymentWaiting: '/payment-waiting',
        PaymentSuccess: '/payment-success',
        SearchResultScreen: '/search',
        GiftingScreen: '/gift',
        SocoPointsHistoryScreen: '/soco-points/history',
        SocoPointsAboutScreen: '/soco-points/about',
        MembershipScreen: '/soco-points/membership',
      },
    },
    Profile: {
      screens: {
        ProfileScreen: '/my-profile',
        StoreScreen: '/my-profile/our-store',
        MyAddressScreen: '/profile/shipping-address',
        WishlistScreen: '/profile/wishlist',
        MyOrdersScreen: '/profile/my-orders',
        MyReviewScreen: '/profile/my-review',
      },
    },
  },
};

export const WebRoutes = Routes.screens.Shop;
